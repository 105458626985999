<template>
  <div class="body">
    <img src="@/assets/img/guide/deptSpread/bg.png" alt="" />
    <van-collapse v-model="activeNames" @change="handleChange">
      <van-collapse-item name="1" :value="value1">
        <template #title>
          <span class="collapse-title">1# 门诊楼</span>
        </template>
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">大厅：挂号咨询处 划价收费 门诊药房</p>
                <p class="card-desc">西侧：儿科门诊，门诊输液大厅</p>
                <p class="card-desc">
                  东侧：感染性疾病科（发热门诊、肠道门诊）
                </p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼连廊</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">东侧：门诊办、病案室、医保办、住院收费</p>
                <p class="card-desc">西侧：泌尿外科</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">西侧：门诊手术室、外科门诊</p>
                <p class="card-desc">东侧：内科门诊，中医门诊、心理科</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼连廊</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  东侧：中药房、功能科（心电图、脑电图、 肌电图）、超声科
                </p>
                <p class="card-desc">西侧：皮肤科、出生证明申领处</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">西侧：不孕不育中心</p>
                <p class="card-desc">东侧：眼科门诊、耳鼻喉门诊、口腔科门诊</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼连廊</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  西侧：妇产科门诊、妇产科手术室、婴儿游泳室
                </p>
                <p class="card-desc">东侧：输血科、血库</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">四楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">儿科一病区 、新生儿重症医学科</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">五楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  健康体检中心 、肾病风湿病区 、内分泌病区
                </p>
              </div>
            </div>
          </van-step>
        </van-steps>
      </van-collapse-item>
      <van-collapse-item name="2" :value="value2">
        <template #title>
          <span class="collapse-title">2# 医技楼</span>
        </template>
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  医学影像科（MRI室 CT室 胃肠造影室 DR室 ）
                </p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  内镜室（胃镜室、肠镜室、13碳检测室）、病理科
                </p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">医学检验科</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">四楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">呼吸内科病区、消化内科病区</p>
              </div>
            </div>
          </van-step>
        </van-steps>
      </van-collapse-item>
      <van-collapse-item name="3" :value="value3">
        <template #title>
          <span class="collapse-title">3# 病房楼B座</span>
        </template>
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  医学影像科 介入中心（DSA）、远程会诊中心
                </p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">心血管内科一病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">心血管内科二病区 、神经内科二病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">四楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">肿瘤科 * 康宁病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">五楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">神经内科一病区</p>
              </div>
            </div>
          </van-step>
        </van-steps>
      </van-collapse-item>
      <van-collapse-item name="5" :value="value3">
        <template #title>
          <span class="collapse-title">5# 病房楼A座</span>
        </template>
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">消毒供应中心 、 静配中心</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">心脏重症监护病房（CCU）</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">妇产科一病区 、 产房</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">四楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">妇产科二病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">五楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">儿科二病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">六楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">全科医学病区</p>
              </div>
            </div>
          </van-step>
        </van-steps>
      </van-collapse-item>
      <van-collapse-item name="7" :value="value3">
        <template #title>
          <span class="collapse-title">7# 外科病房楼</span>
        </template>
        <van-steps direction="vertical" :active="-1">
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">负一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">急诊药房 药品冷库</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">一楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">
                  急诊科急诊病区、急诊X光室、急诊CT室、急诊检 验科
                </p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">二楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">手术室</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">三楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">重症医学科 （CCU、EICU )</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">四楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">骨科一病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">五楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">骨科二病区 、运动医学病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">六楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">神经外科病区 、耳鼻咽喉科病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">七楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">普通外科病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">八楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">泌尿外科病区 、心胸外科病区</p>
              </div>
            </div>
          </van-step>
          <van-step>
            <template #inactive-icon>
              <i class="icon-circle"></i>
            </template>
            <div class="card-item">
              <span class="card-title">九楼</span>
              <div class="card-line"></div>
              <div>
                <p class="card-desc">多媒体会议室</p>
              </div>
            </div>
          </van-step>
        </van-steps>
      </van-collapse-item>
    </van-collapse>
    <!-- <div class="content">
      <span v-html="noticeList.content"></span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "deptSpread",
  data() {
    return {
      activeNames: [],
      noticeList: {},
      value1: "收起",
      value2: "收起",
      value3: "收起",
      value5: "收起",
      value7: "收起",
    };
  },
  methods: {
    handleChange(val) {
      if (val.indexOf("1") == "-1") {
        this.value1 = "展开";
      } else {
        this.value1 = "收起";
      }
      if (val.indexOf("2") == "-1") {
        this.value2 = "展开";
      } else {
        this.value2 = "收起";
      }
      if (val.indexOf("3") == "-1") {
        this.value3 = "展开";
      } else {
        this.value3 = "收起";
      }
      if (val.indexOf("5") == "-1") {
        this.value5 = "展开";
      } else {
        this.value5 = "收起";
      }
      if (val.indexOf("7") == "-1") {
        this.value7 = "展开";
      } else {
        this.value7 = "收起";
      }
    },
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http.get("/api/content/infoByParentId", postData).then((res) => {
        console.log(res.data);
        this.noticeList = res.data.contents.find(
          (item) => item.title == "科室地点"
        );
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.body img {
  width: 10rem;
  height: 7rem;
}
/deep/ .van-collapse {
  text-align: left;
}
.collapse-title {
  font-weight: bold;
  font-size: 0.4rem;
}
.collapse-title:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 15px;
  background-color: #00d48b;
  border-radius: 5px;
  margin-right: 8px;
  position: relative;
  top: 0px;
}
.icon-circle {
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 50%;
  background: -webkit-radial-gradient(
    circle closest-side,
    #fff 50%,
    #adeed8 50%
  );
}
.card-item {
  padding: 0.3rem;
  margin-left: 0.3rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 5px;
}
.card-item:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  left: -3px;
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  transform: rotate(-90deg);
}
.card-title {
  font-size: 0.4rem;
  color: #3a3a3a;
}
.card-line {
  width: 98%;
  height: 1px;
  margin: 8px 0px;
  background-color: #e6e6e6;
}
.card-desc {
  line-height: 0.7rem;
  font-size: 0.4rem;
}
.card-desc:before {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #00d48b;
  margin-right: 0.2rem;
  position: relative;
  top: -5px;
}
/deep/.van-hairline:after {
  border: none;
}
/* .content img{
  width: 99%;
}
.content{
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
  margin-top: 0.2rem;
  text-align: left;
} */
</style>
